import { Card, CardActions, CardContent, Grid } from '@mui/material';
import React from 'react';
import CenterInfoLogo from '../../../../images/appicons/CenterInfoIcon.png';
import { useMyProfileQuery } from '../../../../services/profileService/profile-api';

function CenterInfoCard(){
    const {data} = useMyProfileQuery();
    const canAccess=():boolean=>{
        if(data?.role === 'Admin' || data?.role === 'Contract Admin' ||
           data?.role === 'RevCycle Admin' || data?.role === 'IVVerify'
        || data?.role === 'Quality Team'){
            return true;
        }
        return false;
    }
    return (
        <React.Fragment>
            {canAccess() &&
            <Grid item sm={3}>
            <Card className='card-img-body center-align'>
                <CardContent className='card-img-main'>
                    <a className='btn img-button' href={'/orgHome'}><img className='card-img' src={CenterInfoLogo} alt=''/></a>
                </CardContent>
                <CardActions className='card-img-footer'><h6>Center Information</h6></CardActions>
            </Card>
            </Grid>
            }
        </React.Fragment>
    )
}

export {CenterInfoCard}