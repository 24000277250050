export const Locations = [
    { name: "Coppell", centerCode: "MBM" },
    { name: "Emergency Services", centerCode: "ALL" },
    // { name: "Hill Country- Fredericksburg", centerCode: "ALL" },
    { name: "Lab Services", centerCode: "ALL" },
    { name: "Legent Care", centerCode: "ALL" },
    { name: "Oask Creek-Austin", centerCode: "H01" },
    { name: "Oak Creek", centerCode: "H01" },
    { name: "OR", centerCode: "ALL" },
    { name: "PACU", centerCode: "ALL" },
    { name: "Pharmacy", centerCode: "ALL" },
    { name: "PT Clinic", centerCode: "ALL" },
    { name: "PT-Allen", centerCode: "MBM" },
    { name: "PT-Frisco", centerCode: "MBM" },
    { name: "PT-Plano", centerCode: "MBM" },
    { name: "PT-Richardson", centerCode: "MBM" },
    { name: "PSN Corporate", centerCode: "ALL" },
    { name: "Radiology", centerCode: "ALL" },
];