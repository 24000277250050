import React, { useState } from "react"
import { Grid, GridColumn as Column, GridDataStateChangeEvent, GridRowProps } from "@progress/kendo-react-grid";
import { process, State } from "@progress/kendo-data-query";
import { useParams } from "react-router";
import { useFindAccountByPeriodQuery } from "../../../../services/accountService/account-api";
import { AccountSummaryRequest } from "../../../../services/accountService/commands";
import { AccountGridCommandCell } from "./AccountGridCommandCell";
import { ColumnMenu } from "../../../../utils/ColumnMenu";


function AccountGrid(){
    const {center, month, year} = useParams();
    const {data} = useFindAccountByPeriodQuery(new AccountSummaryRequest(center!, Number(month!), Number(year!)));
    const mapAccounts=()=>{
        let accounts = data === undefined ? [] : data.map(i => ({...i, serviceDate: new Date(i.serviceDate!)}))
        return accounts;
    }
    const defaultGridState:State = {sort:[{field:'serviceDate',dir:'asc'}]};
    const [dataState, setDataState] = useState(defaultGridState);
    const createDataState = (dataState: State) => {
        return {
          dataState: dataState === undefined ? {} :dataState,};
      }; 
    const dataStateChange = (event: GridDataStateChangeEvent) => {
        let updatedState = createDataState(event.dataState);
        setDataState(updatedState.dataState);
      };
      const rowRender = (
        trElement: React.ReactElement<HTMLTableRowElement>,
        props: GridRowProps
      ) => {
        const variance = Math.abs(Number(props.dataItem.variance));
        const approvalStatus = props.dataItem.approvalStatus;
        const yellow = { backgroundColor: "rgb(255, 255, 0,0.32)" };
        const white = { backgroundColor: "rgb(255, 255, 255, 0.32)" };
        const green = { backgroundColor: "rgb(0, 128, 128, 0.32)" };
        const red = { backgroundColor: "rgb(255, 112, 116, 0.32)" };
        const trProps: any = { style: approvalStatus=='Rejected'?red: approvalStatus=='Approved'?green:variance>5000? yellow : white};
        return React.cloneElement(
          trElement,
          { ...trProps },
          trElement.props.children as any
        );
      };
    return <React.Fragment>
        <Grid data={process(mapAccounts(),dataState)} style={{height:'750px'}} 
            onDataStateChange={dataStateChange} rowRender={rowRender}>
            <Column cell={AccountGridCommandCell} width={'75px'}/>
            <Column title='Account' field='accountNumber' width={'150px'} columnMenu={ColumnMenu}/>
            <Column title='Patient' field='patientName' width={'200px'} columnMenu={ColumnMenu}/>
            <Column title='Status' field='status' width={'125px'} columnMenu={ColumnMenu}/>
            <Column title='Service Date' field='serviceDate' format='{0:MM/dd/yyyy}' filter="date" width={'150px'} columnMenu={ColumnMenu}/>
            <Column title='Payer' field='payer' width={'250px'} columnMenu={ColumnMenu}/>
            <Column title='VT' field='visitType' width={'100px'} columnMenu={ColumnMenu}/>
            <Column title='SC' field='serviceCode' width={'100px'} columnMenu={ColumnMenu}/>
            <Column title='Exp. Rev' field='expectedRevenue' format='{0:c}' width={'175px'} columnMenu={ColumnMenu}/>
            <Column title='Final Rev' field='finalRevenue' format='{0:c}' width={'175px'} columnMenu={ColumnMenu}/>
            <Column title='Variance' field='variance' format='{0:c}' width={'150px'} columnMenu={ColumnMenu}/>
            <Column title='Charges' field='charges' format='{0:c}' width={'150px'} columnMenu={ColumnMenu}/>
            <Column title='Proc Count' field='finalProcedureCount' width={'150px'} columnMenu={ColumnMenu}/>
            
            
        </Grid>

    </React.Fragment>
}
export {AccountGrid}