import { AssignedUser } from "../model/varianceRequest/AssignedUser";

export class IncidentAssignUserCommand {
    varianceId: string;
    assignedTo: AssignedUser;
    constructor(varianceId: string, assignedTo: AssignedUser) {
        this.varianceId = varianceId;
        this.assignedTo = assignedTo;

    }
}