import { Stack, Typography } from "@mui/material"
import React from "react"
import { AccountAccrualCard } from "../features/centerSummary/cards/AccountAccrualCard"
import { OutOfPeriodAdjustmentsCard } from "../features/centerSummary/cards/OutOfPeriodAdjustmentsCard"
import { AccountCenterSummaryCommands, AccountLookup } from "../features"
import { useParams } from "react-router"
import { CenterNameView } from "../../../components/orgComponents"
import { RevRecCenterStatusCard } from "../features/centerSummary/cards/RevRecCenterStatusCard"

function RevRecCenterDashboard(){
    const {center, month, year} = useParams();
  

    return <React.Fragment>
        <Stack direction ='row' justifyContent='space-between'>
            <Stack direction='row' gap={1}>
                <CenterNameView centerId={center!}/> 
                <Typography className='psn-green' variant="h6">{month + '-' + year}</Typography>
            </Stack>
            <AccountLookup/>
            <AccountCenterSummaryCommands/>
        </Stack>
        <Stack direction='row' justifyContent='space-around' marginTop='20px'>
            <AccountAccrualCard/>
            {/* <RevRecCenterStatusCard/> */}
            <OutOfPeriodAdjustmentsCard/>
        </Stack>
        <Stack direction='row' justifyContent='center' marginTop='20px' gap={5}>
{/*             <RevRecWorklistCards title="Pending Updates"/>
            <RevRecWorklistCards title="Adj Approvals"/>
            <RevRecWorklistCards title="Placeholder"/>
            <RevRecWorklistCards title="Placeholder"/> */}
        </Stack>
        
    </React.Fragment>
}
export {RevRecCenterDashboard}