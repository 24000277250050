import React from "react";
import { useNavigate } from "react-router";
import { useAppDispatch } from "../../../../app/hooks";
import { toggleLookupUserModal } from "../../../../app/slices/ProfileModuleSlice";
import { RoleChangeCommand } from "../../../../services/profileService/commands";
import { Profile } from "../../../../services/profileService";
import { useMyProfileQuery, useRoleChangeMutation, useRolesQuery } 
    from "../../../../services/profileService/profile-api";
import { Button, MenuItem, TextField } from "@mui/material";
import { Stack } from "@mui/system";
import SearchIcon from '@mui/icons-material/Search';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import { ProfileAttrbEdit } from "./ProfileAttrbEdit";

type ownProps={
    profile?:Profile;
}
function ProfileHeader(props:ownProps){
    const dispatch = useAppDispatch();
    const roles = useRolesQuery();
    const [changeRole] = useRoleChangeMutation();
    const myProfile = useMyProfileQuery();
    const navigate = useNavigate();

    const saveRole=(e:any)=>{
        let command = new RoleChangeCommand(props.profile!.id,e.target.value);
        changeRole(command);
    }
    return <React.Fragment>
        <Stack direction='row' justifyContent='space-between' alignItems='flex-end'>
            <h4>{props.profile?.identity}<ProfileAttrbEdit profile={props.profile}/></h4>
            {myProfile.data?.isAdmin === false ?
                <h4 className="center-align">{props.profile?.roleDescription}</h4> :
                <h4 className='center-align'>
                    <TextField name='role' select className="center-align" size='small' sx={{width:'350px'}} value={props.profile?.role} onChange={(e)=>saveRole(e)}>
                        {roles.data?.map(i => 
                            <MenuItem className='h5' value={i.role}>{i.roleName}</MenuItem>    
                        )}
                    </TextField> 
                </h4>
            }
            <Stack direction='row'>
            {(myProfile.data?.role === 'Admin' || myProfile.data?.role === 'RevCycle Admin') && myProfile.data?.id === props.profile?.id &&
                <Button variant='outlined' size='large' onClick={()=>dispatch(toggleLookupUserModal())}><SearchIcon/>Search</Button>}
            {(myProfile.data?.role === 'Admin' || myProfile.data?.role === 'RevCycle Admin') && myProfile.data?.id !== props.profile?.id &&
                <Button variant='outlined' color ='success' size='large' onClick={()=>navigate(-1)}><KeyboardDoubleArrowLeftIcon/>Back  to your profile</Button>}
            </Stack>
        </Stack>
    </React.Fragment>
}

export {ProfileHeader};