export const Departments = [
    'Biomedical Engineering',
    'Case Management',
    'Emergency Department',
    'Environment of Care',
    'Facilities',
    'Food Services',
    'HIM',
    'ICU',
    'Imaging',
    'Infection Control',
    'Laboratory',
    'Materials Management',
    'Medical Surgical',
    'Patient Access',
    'Pharmacy',
    'Pre-Admit Testing',
    'Pre Operative',
    'Surgery',
    'PACU',
    'SPD',
    'Scheduling'
];