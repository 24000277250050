import { VarianceRequest } from "../../../services/varianceService/model";
import { CompositeSpecification } from "../../../utils/Specification";

export class VarianceDetailsValid extends CompositeSpecification<VarianceRequest>{
    IsSatisfiedBy(candidate: VarianceRequest): boolean {
        if(candidate.department === "")
            return false;
        if(candidate.incidentType === "")
            return false;
        if(candidate.location.name === "")
            return false;

        return true;
    }
}