import { Button } from "@mui/material";
import React from "react";
import { VarianceStartCommand } from "../../../../../services/varianceService/commands";
import { useStartPatientVarianceMutation } from "../../../../../services/varianceService/variance-api";
import { Individual } from "../../../../../services/varianceService/model";
import { useNavigate, useParams } from "react-router";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { ReduxUpdate } from "../../../../../utils/ReduxUpdateCommands";
import { editVarianceIndividual, setVarianceIndividual } from "../../../../../services/varianceService/model/varianceRequest/VarianceRequestSlice";

function VarianceInititiateIndividualCommand(individual: Individual) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const variance = useAppSelector(
    (state) => state.varianceRequest.varianceRequest
  );
  const command = new VarianceStartCommand(variance);
  const saveIdent = () => {
    dispatch(setVarianceIndividual(individual));
    startVariance();
  };
  const [initiate] = useStartPatientVarianceMutation();

  const startVariance = () => {
    initiate(command)
      .unwrap()
      .then((fullfilled) => {
        navigate("/varianceRequestDetail/" + fullfilled.id);
      });
  };

  return (
    <React.Fragment>
      <Button
        onClick={() => {
          saveIdent();
        }}
      >
        Submit
      </Button>
    </React.Fragment>
  );
}

export { VarianceInititiateIndividualCommand };
