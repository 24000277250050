import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getToken } from "../../utils/AdalConfig";
import { VarianceService } from "../../utils/ServiceLocator";
import { IndividualSaveCommand, VarianceStartCommand, VarianceStatusPostCommand, VarianceWorklistRequest, VarianceWorklistSearchRequest } from "./commands";
import { Individual, VarianceRequest, Worklist } from "./model";
import { VariancePostCommand } from "./commands/VariancePostCommand";
import { VarianceApprovalPostCommand } from "./commands/VarianceApprovalPostCommand";
import { VarianceApprovalPostResult } from "./commands/VarianceApprovalPostResult";
import { VarianceRejectPostResult } from "./commands/VarianceRejectPostResult";
import { VarianceRejectPostCommand } from "./commands/VarianceRejectPostCommand";
import { IncidentCommentAddCommand } from "./commands/IncidentCommentAddCommand";
import { IncidentComment } from "./model/varianceRequest/IncidentComment";
import { VariancePendingPostResult } from "./commands/VariancePendingPostResult";
import { VariancePendingPostCommand } from "./commands/VariancePendingPostCommand";
import { VarianceCompletePostCommand } from "./commands/VarianceCompletePostCommand";
import { VarianceCompletePostResult } from "./commands/VarianceCompletePostResult";
import { IncidentWitnessAddCommand } from "./commands/WitnessAddCommand";
import { IncidentWitnessRemoveCommand } from "./commands/WitnessRemoveCommand";
import { IncidentAssignUserCommand } from "./commands/IncidentAssignUserCommand";
import { VarianceMyWorklistRequest } from "./commands/VarianceMyWorklistRequest";
import { VariancePendingApprovalPostCommand } from "./commands/VariancePendingApprovalPostCommand";
import { VariancePendingApprovalPostResult } from "./commands/VariancePendingApprovalPostResult";
import { VarianceDocument } from "./model/varianceRequest/VarianceDocuments";
import { VarianceDocRemoveCommand } from "./commands/VarianceDocRemoveCommand";

export const varianceApi = createApi({
    reducerPath: 'varianceApi',
    baseQuery: fetchBaseQuery({
        baseUrl: VarianceService,
        prepareHeaders: (headers) => {
            const token = getToken();
            headers.set('Authorization', 'Bearer ' + token);
            headers.set('content-type', 'application/json')
            return headers;
        }
    }),
    tagTypes: ['variance', 'individual', 'worklist', 'search'],
    endpoints: (builder) => ({
        startPatientVariance: builder.mutation<VarianceRequest, VarianceStartCommand>({
            query: (body) => {
                return { url: 'VarianceStart', method: 'POST', body: body, }
            },
        }),
        IndividualSaveCommand: builder.mutation<VarianceRequest, IndividualSaveCommand>({
            query:(body:IndividualSaveCommand)=>({url:'Individual',method:('POST'),body,}),
            invalidatesTags:['individual']
        }),
        findWorklist: builder.query<Worklist[], VarianceWorklistRequest>({
            query: (request: VarianceWorklistRequest) => 'varianceWorklist?center='+ request.center + '&user='  + request.center + '&queryParams=' + request.queryParam + '&isAdmin=' + request.isAdmin,
            providesTags: ['worklist']
        }),
        myWorklist: builder.query<Worklist[], VarianceMyWorklistRequest>({
            query: (request: VarianceMyWorklistRequest) => 'varianceMyWorklist?center=' + request.center + '&user=' + request.user + '&isAdmin=' + request.isAdmin,
            providesTags: ['worklist']
        }),
        findVarianceRequest: builder.query<VarianceRequest, string>({
            query: (id: string) => 'VarianceRequestDetail?Id=' + id,
            providesTags: ['variance']
        }),
        varianceWorklistSearch: builder.query<Worklist[], VarianceWorklistSearchRequest>({
            query: (request: VarianceWorklistSearchRequest) => 'search?center=' + request.center + '&searchCriteria=' + request.searchCriteria,
            providesTags: ['search']
        }),
        varianceSave: builder.mutation<VarianceRequest, VariancePostCommand>({
            query: (body) => {
                return { url: 'Variance', method: 'POST', body: body, }
            },
            invalidatesTags: ['variance', 'worklist']
        }),
        varianceStatusUpdate: builder.mutation<VarianceRequest, VarianceStatusPostCommand>({
            query: (body) => {
                return { url: 'VarianceStatusUpdate', method: 'POST', body: body, }
            }, invalidatesTags: ['worklist']
        }),
        varianceApprove: builder.mutation<VarianceApprovalPostResult, VarianceApprovalPostCommand>({
            query: (body) => {
                return { url: 'VarianceApproval', method: 'POST', body: body, }
            }
        }),
        variancePending: builder.mutation<VariancePendingPostResult, VariancePendingPostCommand>({
            query: (body) => {
                return { url: 'VariancePending', method: 'POST', body: body, }
            }
        }),
        variancePendingApprove: builder.mutation<VariancePendingApprovalPostResult, VariancePendingApprovalPostCommand>({
            query: (body) => {
                return { url: 'VariancePendingApproval', method: 'POST', body: body, }
            }
        }),
        varianceReject: builder.mutation<VarianceRejectPostResult, VarianceRejectPostCommand>({
            query: (body) => {
                return { url: 'VarianceReject', method: 'POST', body: body, }
            }
        }),
        varianceComplete: builder.mutation<VarianceCompletePostResult, VarianceCompletePostCommand>({
            query: (body) => {
                return { url: 'VarianceComplete', method: 'POST', body: body, }
            }
        }),
        incidentCommentAdd:builder.mutation<IncidentComment,IncidentCommentAddCommand>({
            query:(body:IncidentCommentAddCommand) => {
                return{url:'CommentAdd',method:'POST',body:body}
            }
        }),
        incidentWitnessAdd:builder.mutation<Individual,IncidentWitnessAddCommand>({
            query:(body:IncidentWitnessAddCommand)=>({url:'WitnessAdd',method:('POST'),body,}),
            invalidatesTags:['variance']
        }),
        incidentWitnessRemove:builder.mutation<Individual, IncidentWitnessRemoveCommand>({
            query:(body:IncidentWitnessRemoveCommand)=>({url:'WitnessRemove',method:('POST'),body,}),
            invalidatesTags:['variance']
        }),
        incidentAssignUser:builder.mutation<VarianceRequest, IncidentAssignUserCommand>({
            query:(body:IncidentAssignUserCommand)=>({url:'AssignUser',method:('POST'),body,}),
            invalidatesTags:['variance']
        }),
        documentRemove:builder.mutation<VarianceDocument,VarianceDocRemoveCommand>({
            query:(body) =>{            
                return {url:'DocumentRemove', method:'POST', body:body,}
            }
        }),

    })
});

export const { useStartPatientVarianceMutation, useFindWorklistQuery, useFindVarianceRequestQuery, useVarianceSaveMutation,
    useVarianceStatusUpdateMutation, useVarianceApproveMutation, useVariancePendingMutation, useVariancePendingApproveMutation,
    useVarianceRejectMutation, useVarianceCompleteMutation, useVarianceWorklistSearchQuery, useIncidentCommentAddMutation,
    useIncidentWitnessAddMutation, useIncidentWitnessRemoveMutation, useIncidentAssignUserMutation, useMyWorklistQuery, useDocumentRemoveMutation } = varianceApi;