import {
  Stack,
  Box,
  Typography,
  IconButton,
  Autocomplete,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../../app/hooks";
import {
  editVarianceLocation,
  editVarianceLocationDetails,
} from "../../../../../../services/varianceService/model/varianceRequest/VarianceRequestSlice";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import { Locations } from "./Locations";

function LocationSelection(props: { canEdit: boolean }) {
  const dispatch = useAppDispatch();
  const incident = useAppSelector(
    (state) => state.varianceRequest.varianceRequest
  );
  const [incidentEdit, setIncidentEdit] = useState(incident.location === null || incident.location.name === "");
  const [value, setValue] = React.useState({ name: "" });
  const [open, toggleOpen] = React.useState(false);
  const handleClose = () => {
    setDialogValue({
      name: "",
    });
    toggleOpen(false);
  };

  const [dialogValue, setDialogValue] = React.useState({
    name: "",
  });

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setValue({
      name: dialogValue.name,
    });
    dispatch(
      editVarianceLocationDetails({ name: "name", value: dialogValue.name })
    );
    handleClose();
  };

  return (
    <React.Fragment>
      <Box>
        {incidentEdit === false && (
          <Stack direction="row">
            <Typography variant="h6" className="psn-green">
              Location: &nbsp;
            </Typography>
            <Typography variant="h6" className="psn-green">
              {incident.location.name}{" "}
              {incident.locationDetails && incident.locationDetails.name
                ? ` - ${incident.locationDetails.name}`
                : ""}
            </Typography>
            <IconButton
              disabled={!props.canEdit}
              size="small"
              onClick={() => setIncidentEdit(!incidentEdit)}
            >
              <ModeEditOutlinedIcon fontSize="small" />
            </IconButton>
          </Stack>
        )}
        {(incidentEdit === true || incident.location === null || incident.location.name === "") && (
          <Stack direction="row">
            <Autocomplete
              disabled={!props.canEdit}
              sx={{ width: 300 }}
              onChange={(_event, value) => {
                dispatch(editVarianceLocation({ name: "name", value: value?.name! }));
                toggleOpen(true);
              }}
              options={Locations.filter((location) => location.centerCode === "ALL" || location.centerCode === incident.center)}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField {...params} label="Where did this incident occur?" />
              )}
            />
            <IconButton
              disabled={!props.canEdit}
              size="small"
              onClick={() => {
                setIncidentEdit(!incidentEdit);
                setValue({
                  name: dialogValue.name,
                });
              }}
            >
              <CheckCircleOutlineOutlinedIcon fontSize="small" />
            </IconButton>
            <Dialog open={open} onClose={handleClose}>
              <form onSubmit={handleSubmit}>
                <DialogTitle>Location Details</DialogTitle>
                <DialogContent>
                  <DialogContentText sx={{ marginBottom: "16px" }}>
                    Any Specific Location Details? (Room #, etc.)
                  </DialogContentText>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    value={dialogValue.name}
                    onChange={(event) =>
                      setDialogValue({
                        ...dialogValue,
                        name: event.target.value,
                      })
                    }
                    label="location details"
                    type="text"
                    variant="outlined"
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose}>No Details</Button>
                  <Button type="submit">Add</Button>
                </DialogActions>
              </form>
            </Dialog>
          </Stack>
        )}
      </Box>
    </React.Fragment>
  );
}

export { LocationSelection };
