import {
  Stack,
  Box,
  Typography,
  IconButton,
  Autocomplete,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../../app/hooks";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import { editVariance } from "../../../../../../services/varianceService/model/varianceRequest/VarianceRequestSlice";
import { Departments } from "./Departments";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";


function DepartmentSelection(props: { canEdit: boolean }) {
  const dispatch = useAppDispatch();
  const incident = useAppSelector(
    (state) => state.varianceRequest.varianceRequest
  );
  const [incidentEdit, setIncidentEdit] = useState(incident.department === null || incident.department === "");
  const [open, toggleOpen] = React.useState(false);

  return (
    <React.Fragment>
      <Box>
        {incidentEdit === false && (
          <Stack direction="row">
            <Typography variant="h6" className="psn-green">
              Department: &nbsp;
            </Typography>
            <Typography variant="h6" className="psn-green">
              {incident.department}              
            </Typography>
            <IconButton
              disabled={!props.canEdit}
              size="small"
              onClick={() => setIncidentEdit(!incidentEdit)}
            >
              <ModeEditOutlinedIcon fontSize="small" />
            </IconButton>
          </Stack>
        )}
        {incidentEdit === true && (
          <Stack direction="row">
            <Autocomplete
              disabled={!props.canEdit}
              sx={{ width: 300 }}
              onChange={(_event, value) => {
                dispatch(editVariance({ name: "department", value: value }));
                toggleOpen(true);
              }}
              options={Departments}
              renderInput={(params) => (
                <TextField {...params} label="Department Incident Occured?" />
              )}
            /><IconButton
            disabled={!props.canEdit}
            size="small"
            onClick={() => {
              setIncidentEdit(!incidentEdit);
            }}
            >
            <CheckCircleOutlineOutlinedIcon fontSize="small" />
            </IconButton>            
          </Stack>
        )}
      </Box>
    </React.Fragment>
  );
}

export { DepartmentSelection };
